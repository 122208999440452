import { Button as BabylonButton } from "babylonjs-gui";

class Button extends BabylonButton {
  constructor(name, text) {
    super();

    const button = Button.CreateSimpleButton(name, text);
    button.height = "30px";
    button.color = "white";
    button.alpha = 0.75;
    button.fontSize = 14;
    button.thickness = 0;

    return button;
  }
}

export default Button;

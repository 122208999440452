import React, {useEffect, useRef} from "react";
import Tween from "tween.js";
import { func } from "prop-types";
import hydrogen from "../../assets/hydrogen.png";
import oxygen from "../../assets/oxygen.png";
import water from "../../assets/water.png";
import {initializeArToolkit, initializeRenderer, getMarker, loadTexture, loadGltf} from "../../utils";
import { CloseButton } from "../../components";

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const ZOOM_THRESHOLD = 5;
const ZOOM_DIFFERENCE = 3;
const {
  Camera,
  Scene,
  Group,
  Mesh,
  MeshBasicMaterial,
  Vector3,
  DirectionalLight,
  PlaneGeometry,
  SphereGeometry,
  MeshStandardMaterial,
  Box3,
  BoxHelper,
  AmbientLight,
} = window.THREE;

const ChemistryScene = ({ onClose }) => {
  const canvasRef = useRef();
  
  useEffect(() => {
    let requestId = null;
    const store = {
      oxygenInitialZ: null,
      oxygenZoomed: false,
      hydrogen1: {
        assign: null,
        tweenInProgress: false,
      },
      hydrogen2: {
        assign: null,
        tweenInProgress: false,
      },
    };
  
    const checkForIntersection = (storeKey, currentBox, targetBox, currentGroup, targetGroup, sphere) => {
      if (currentBox.intersectsBox(targetBox) && !store[storeKey].assign && !store[storeKey].tweenInProgress) {
        const sphereToMovePosition = new Vector3();
        targetGroup.getObjectByName("sphere").getWorldPosition(sphereToMovePosition);
        const isLeftSide = sphere.position.x < sphereToMovePosition.x;
        const direction = new Vector3(isLeftSide ? -0.35 : 0.35, 0, 0).applyQuaternion(targetGroup.quaternion);
        const newPos = new Vector3(0, 0, 0).addVectors(sphereToMovePosition, direction);
        store[storeKey].tweenInProgress = true;
        new Tween.Tween(sphere.position)
          .to({...newPos}, 350)
          .onComplete(() => {
            store[storeKey] = {tweenInProgress: false, assign: isLeftSide ? "left" : "right"}
          })
          .start();
      } else if (!currentBox.intersectsBox(targetBox) && !store[storeKey].tweenInProgress && store[storeKey].assign) {
        store[storeKey].tweenInProgress = true;
        setTimeout(() => {
          if (currentGroup.visible && !currentBox.intersectsBox(targetBox)) {
            const direction = new Vector3(0, 1, 0).applyQuaternion(currentGroup.quaternion);
            const newPos = new Vector3(0, 0, 0).addVectors(currentGroup.position.clone(), direction.multiplyScalar(1));
            new Tween.Tween(sphere.position)
              .to({...newPos}, 350)
              .onComplete(() => {
                store[storeKey] = { tweenInProgress: false, assign: null }
              })
              .start();
          } else {
            store[storeKey].tweenInProgress = false;
          }
        }, 300);
      }
    };
    
    const prepareSpherePosition = (storeKey, currentGroup, targetGroup, sphere, isZoomed) => {
      if (!store[storeKey].tweenInProgress && ((currentGroup.visible && !store[storeKey].assign) || (targetGroup.visible && store[storeKey].assign)) && !isZoomed) {
        // eslint-disable-next-line
        sphere.visible = true;
        const root = store[storeKey].assign ? targetGroup : currentGroup;
    
        if (!store[storeKey].assign) {
          const direction = new Vector3(0, 1, 0).applyQuaternion(root.quaternion);
          const newPos = new Vector3(0, 0, 0).addVectors(root.position.clone(), direction.multiplyScalar(1));
          sphere.position.set(newPos.x, newPos.y, newPos.z);
        } else {
          const direction = new Vector3(store[storeKey].assign === "left" ? -0.35 : 0.35, 0, 0).applyQuaternion(root.quaternion);
          const targetPos = new Vector3();
          targetGroup.getObjectByName("sphere").getWorldPosition(targetPos);
          const newPos = new Vector3(0, 0, 0).addVectors(targetPos, direction);
          sphere.position.set(newPos.x, newPos.y, newPos.z);
        }
      } else if ((!currentGroup.visible && !store[storeKey].assign) || (!targetGroup.visible && store[storeKey].assign && !store[storeKey].tweenInProgress) || isZoomed) {
        // eslint-disable-next-line
        sphere.visible = false;
      }
    };
    
    const renderer = initializeRenderer(canvasRef.current);
    const hydrogen1Box = new Box3();
    const hydrogen2Box = new Box3();
    const oxygenBox = new Box3();
    const scene = new Scene();
    const camera = new Camera();
    const directionalLight = new DirectionalLight();
    const ambientLight = new AmbientLight();
    const hydrogenGroup1 = new Group();
    const hydrogenGroup2 = new Group();
    const oxygenGroup = new Group();
    scene.add(camera, hydrogenGroup1, hydrogenGroup2, oxygenGroup, directionalLight, ambientLight);
    
    const { arToolkitContext, updateToolkitContent, destroy } = initializeArToolkit(renderer, camera);
    getMarker(arToolkitContext, hydrogenGroup1, {type: "barcode", barcodeValue: 4});
    getMarker(arToolkitContext, hydrogenGroup2, {type: "barcode", barcodeValue: 5});
    getMarker(arToolkitContext, oxygenGroup, {type: "barcode", barcodeValue: 1});
    const imageGeometry = new PlaneGeometry(1, 1, 4, 4);
    const hydrogenSphereGeometry = new SphereGeometry(0.15, 32, 32);
    const hydrogenSphereMaterial = new MeshStandardMaterial({color: 0xa70947, opacity: 0.85, transparent: true});
    const hydrogenSphere1 = new Mesh(hydrogenSphereGeometry, hydrogenSphereMaterial);
    const hydrogenSphere2 = new Mesh(hydrogenSphereGeometry, hydrogenSphereMaterial);
    
    let hydrogenHelper1 = null;
    let hydrogenHelper2 = null;
    let oxygenHelper = null;
    
    loadGltf({ url: "assets/drop.gltf" }).then(({ texture }) => {
      const waterMesh = texture.scene.children[0];
      waterMesh.name = "water";
      waterMesh.material.opacity = 0.65;
      waterMesh.material.transparent = true;
      waterMesh.scale.set(0.15, 0.15, 0.15);
      waterMesh.position.set(0, 0.8, 0);
      waterMesh.visible = false;
      oxygenGroup.add(waterMesh);
    });
    
    loadTexture({ url: hydrogen }).then(({ texture }) => {
      const hydrogenMaterial = new MeshBasicMaterial({map: texture});
      const hydrogenMesh1 = new Mesh(imageGeometry, hydrogenMaterial);
      hydrogenMesh1.rotation.x = -Math.PI / 2;
      hydrogenMesh1.scale.set(1.75, 1.75, 1.75);
      const hydrogenMesh2 = new Mesh(imageGeometry, hydrogenMaterial);
      hydrogenMesh2.rotation.x = -Math.PI / 2;
      hydrogenMesh2.scale.set(1.75, 1.75, 1.75);
      hydrogenHelper1 = new BoxHelper(hydrogenMesh1, 0x00ff00);
      hydrogenHelper1.visible = false;
      hydrogenHelper2 = new BoxHelper(hydrogenMesh2, 0x00ff00);
      hydrogenHelper2.visible = false;
      hydrogenGroup1.add(hydrogenMesh1);
      hydrogenGroup2.add(hydrogenMesh2);
      scene.add(hydrogenHelper1, hydrogenHelper2);
    });
    
    loadTexture({ url: oxygen }).then(({ texture }) => {
      const oxygenMaterial = new MeshBasicMaterial({ map: texture });
      const oxygenMesh = new Mesh(imageGeometry, oxygenMaterial);
      oxygenMesh.name = "oxygenCard";
      oxygenMesh.rotation.x = -Math.PI / 2;
      oxygenMesh.scale.set(1.75, 1.75, 1.75);
      const oxygenSphereGeometry = new SphereGeometry(0.2, 32, 32);
      const oxygenSphereMaterial = new MeshStandardMaterial({color: 0x56bcdd, opacity: 0.85, transparent: true});
      const oxygenSphere = new Mesh(oxygenSphereGeometry, oxygenSphereMaterial);
      oxygenSphere.position.set(0, oxygenGroup.position.y + 1, 0);
      oxygenSphere.name = "sphere";
      oxygenGroup.add(oxygenMesh, oxygenSphere);
      oxygenHelper = new BoxHelper(oxygenMesh, 0x00ff00);
      oxygenHelper.visible = false;
      scene.add(hydrogenSphere1, hydrogenSphere2, oxygenHelper);
    });
    
    loadTexture({ url: water }).then(({ texture }) => {
      const waterMaterial = new MeshBasicMaterial({ map: texture });
      const waterMesh = new Mesh(imageGeometry, waterMaterial);
      waterMesh.name = "waterCard";
      waterMesh.rotation.x = -Math.PI / 2;
      waterMesh.scale.set(1.75, 1.75, 1.75);
      waterMesh.visible = false;
      oxygenGroup.add(waterMesh);
    });
    
    const renderSceneObjects = () => {
      const isOxygenZoomed = Boolean(store.hydrogen1.assign
        && store.hydrogen2.assign
        && store.oxygenInitialZ !== null
        && !(hydrogenGroup2.visible || hydrogenGroup1.visible)
        && ((Math.abs(oxygenGroup.position.z) <= ZOOM_THRESHOLD)
          || (Math.abs(store.oxygenInitialZ) > Math.abs(oxygenGroup.position.z)
            && (Math.abs(store.oxygenInitialZ) - Math.abs(oxygenGroup.position.z) >= ZOOM_DIFFERENCE))));
      
      const waterMesh = oxygenGroup.getObjectByName("water");
      const oxygenMesh = oxygenGroup.getObjectByName("sphere");
  
      if (waterMesh && oxygenMesh) {
        waterMesh.visible = isOxygenZoomed;
        oxygenMesh.visible = !isOxygenZoomed;
      }
      
      if (oxygenGroup.visible && oxygenHelper) {
        if (hydrogenGroup1.visible && hydrogenHelper1) {
          hydrogenHelper1.update();
          hydrogen1Box.setFromObject(hydrogenHelper1);
          checkForIntersection("hydrogen1", hydrogen1Box, oxygenBox, hydrogenGroup1, oxygenGroup, hydrogenSphere1);
        }
  
        if (hydrogenGroup2.visible && hydrogenHelper2) {
          hydrogenHelper2.update();
          hydrogen2Box.setFromObject(hydrogenHelper2);
          checkForIntersection("hydrogen2", hydrogen2Box, oxygenBox, hydrogenGroup2, oxygenGroup, hydrogenSphere2);
        }
        
        const oxygenCard = oxygenGroup.getObjectByName("oxygenCard");
        const waterCard = oxygenGroup.getObjectByName("waterCard");
        if (oxygenCard && waterCard) {
          oxygenCard.visible = !(store.hydrogen1.assign && store.hydrogen2.assign);
          waterCard.visible = Boolean(store.hydrogen1.assign && store.hydrogen2.assign);
        }
        if (!store.oxygenInitialZ) {
          store.oxygenInitialZ = oxygenGroup.position.z;
        }
        oxygenHelper.update();
        oxygenBox.setFromObject(oxygenHelper);
      } else {
        store.oxygenInitialZ = null;
      }
      
      prepareSpherePosition("hydrogen1", hydrogenGroup1, oxygenGroup, hydrogenSphere1, isOxygenZoomed);
      prepareSpherePosition("hydrogen2", hydrogenGroup2, oxygenGroup, hydrogenSphere2, isOxygenZoomed);
    };
    
    const animate = () => {
      requestId = requestAnimationFrame(animate);
      Tween.update();
      renderer.render(scene, camera);
      updateToolkitContent();
      renderSceneObjects();
    };
    
    requestId = requestAnimationFrame(animate);
    
    return () => {
      renderer.dispose();
      destroy();
      if (requestId) {
        cancelAnimationFrame(requestId);
      }
    };
  }, []);
  return (
    <>
      <CloseButton onClick={onClose}/>
      <canvas ref={canvasRef}/>
    </>
  );
};

ChemistryScene.propTypes = propTypes;
ChemistryScene.defaultProps = defaultProps;

export default ChemistryScene;

import React, {useCallback, useState} from "react";
import { func } from "prop-types";
import { HouseFly, NasaLaunchPad, PicDumidiDossau, Colosseum, Mountsthelen, Card, CloseButton } from "../../components";
import { models3dStatic } from "../../duck";

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const StaticScene = ({ onClose }) => {
  const [currentModel, setModel] = useState(null);
  const handleClose = useCallback(() => currentModel ? setModel(null) : onClose(), [currentModel, onClose]);
  return (
    <>
      <CloseButton onClick={handleClose} />
      {!currentModel && (
        <div
          className="scene"
        >
          {models3dStatic.map(model => (
            <Card key={model.key} model={model} onClick={() => setModel(model.title)}/>
          ))}
        </div>
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: currentModel ? "block" : "none",
        }}
      >
        {currentModel === "House Fly" && <HouseFly />}
        {currentModel === "NASA Launch Pad" && <NasaLaunchPad />}
        {currentModel === "Pic Dumidi Dossau" && <PicDumidiDossau />}
        {currentModel === "Roman Colosseum" && <Colosseum />}
        {currentModel === "Mountsthelen" && <Mountsthelen />}
      </div>
    </>
  )
};

StaticScene.propTypes = propTypes;
StaticScene.defaultProps = defaultProps;

export default StaticScene;
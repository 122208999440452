import { MeshBuilder } from "babylonjs";
import { Ellipse, TextBlock } from "babylonjs-gui";

class Point extends Ellipse {
  constructor(number, meshOptions, scene) {
    super();
    this.width = "22.5px";
    this.height = "22.5px";
    this.color = "white";
    this.thickness = 2;
    this.background = "black";
    this.alpha = 0.65;
    this.name = `point${number}`;
    this.index = number - 1;

    const pointText = new TextBlock();
    pointText.text = number.toString();
    pointText.fontSize = 11;
    this.addControl(pointText);

    if (meshOptions) {
      this.mesh = MeshBuilder.CreateSphere(`point${number}`, {}, scene);
      this.mesh.visibility = 0;
      this.mesh.position = meshOptions.position;

      if (meshOptions.bone)
        {this.mesh.attachToBone(meshOptions.bone, meshOptions.boneMesh);}
    }

    this.onPointerEnterObservable.add(this.setActive.bind(this, true));
    this.onPointerOutObservable.add(this.setActive.bind(this, false));
  }

  linkWithPointMesh() {
    if (this.mesh) {
      this.linkWithMesh(this.mesh);
    } else {
      throw new Error(
        `There is no mesh provided for this point: ${this.name}!`,
      );
    }
  }

  setActive(isActive) {
    this.color = isActive ? "#d31517" : "white";
  }
}

export default Point;

import React from "react";
import "./spinner.css";

const Spinner = () => (
  <div className="lds-ripple">
    <div />
    <div />
  </div>
);

export default Spinner;
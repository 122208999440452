import React, { useState, useCallback } from "react";
import { func } from "prop-types";
import { Ant, Endeavour, PlantCell, Shark, Spider, SteamEngine, Galaxy, ElectricCircuit, Heart, Card, CloseButton } from "../../components";
import { models3dAnimated } from "../../duck";

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const AnimatedScene = ({ onClose }) => {
  const [currentModel, setModel] = useState(null);
  const handleClose = useCallback(() => currentModel ? setModel(null) : onClose(), [currentModel]);
  return (
    <>
      <CloseButton onClick={handleClose} />
      {!currentModel && (
        <div
          className="scene"
        >
          {models3dAnimated.map(model => (
            <Card key={model.key} model={model} onClick={() => setModel(model.title)}/>
          ))}
        </div>
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: currentModel ? "block" : "none",
        }}
      >
        {currentModel === "Heart" && <Heart />}
        {currentModel === "Galaxy" && <Galaxy />}
        {currentModel === "Shark" && <Shark />}
        {currentModel === "Endeavour" && <Endeavour />}
        {currentModel === "Steam Engine" && <SteamEngine />}
        {currentModel === "Spider" && <Spider />}
        {currentModel === "Ant" && <Ant />}
        {currentModel === "Plant Cell" && <PlantCell />}
        {currentModel === "Electric Circuit" && (
          <ElectricCircuit />
        )}
      </div>
    </>
  )
};

AnimatedScene.propTypes = propTypes;
AnimatedScene.defaultProps = defaultProps;

export default AnimatedScene;
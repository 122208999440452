/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { func } from "prop-types";
import logo from "../../assets/logo.png";
import { Spinner, CloseButton } from "../../components";
import { models } from "../../duck";
import {initializeArToolkit, initializeRenderer, getMarker, loadGltf, loadTexture} from "../../utils";
import ChemistryScene from "../chemistry/Chemistry";

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const {
  Camera,
  Scene,
  Group,
  Mesh,
  MeshBasicMaterial,
  Vector3,
  DirectionalLight,
  PlaneGeometry,
  SphereGeometry,
  MeshStandardMaterial,
  Box3,
  BoxHelper,
  AmbientLight,
  TextGeometry,
  MeshPhongMaterial,
  GeometryUtils,
  AnimationMixer,
  Clock,
  LoopRepeat,
} = window.THREE;

const OtherScene = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const canvasRef = useRef();
  const animations = useRef({});
  
  useEffect(() => {
    let requestId = null;
    let arData = models;
    const store = {
      loading: true,
    };
    const clock = new Clock();
    const renderer = initializeRenderer(canvasRef.current);
    renderer.gammaOutput = true;
    renderer.gammaFactor = 2.2;
    const scene = new Scene();
    const camera = new Camera();
    const directionalLight = new DirectionalLight();
    const ambientLight = new AmbientLight(0xffffff);
    const imageGeometry = new PlaneGeometry(1, 1, 4, 4);
    scene.add(camera, directionalLight, ambientLight);
    const { arToolkitContext, updateToolkitContent, destroy } = initializeArToolkit(renderer, camera);
    
    Promise.all([
      Promise.all(arData.map(item => {
        const group = new Group();
        scene.add(group);
        getMarker(arToolkitContext, group, {type: "barcode", barcodeValue: item.id});
        return loadGltf({ url: item.url, item: { ...item, group }})
      })),
      loadTexture({ url: logo }),
    ]).then(([models, logo]) => {
      models.map(({ texture, data }, index) => {
        if (texture.animations.length) {
          const animationMixer = new AnimationMixer(texture.scene);
          const clipAction = animationMixer.clipAction(texture.animations[0]);
          clipAction.loop = LoopRepeat;
          data.group.animationMixer = animationMixer;
          data.group.clipAction = clipAction;
        }
        const material = new MeshBasicMaterial({ map: logo.texture });
        const mesh = new Mesh(imageGeometry, material);
        mesh.rotation.x = -Math.PI / 2;
        mesh.scale.set(1.4, 1.4, 1.4);
        texture.scene.scale.set(data.scale, data.scale, data.scale);
        texture.scene.position.set(0, 0.75, 0);
        data.group.add(mesh, texture.scene,);
      });
      setLoading(false);
    });
    
    const animate = () => {
      requestId = requestAnimationFrame(animate);
      renderer.render(scene, camera);
      updateToolkitContent();
      scene.children.forEach(child => {
        if (child.animationMixer) {
          if (child.visible) {
            if (!child.clipAction.isRunning()) {
              child.clipAction.play();
            }
            child.animationMixer.update(clock.getDelta());
          } else if (!child.visible && child.clipAction.isRunning()) {
            child.clipAction.stop();
          }
        }
      })
    };
    
    requestId = requestAnimationFrame(animate);
    
    return () => {
      renderer.dispose();
      destroy();
      if (requestId) {
        cancelAnimationFrame(requestId);
      }
    };
  }, []);
  return (
    <div>
      <CloseButton onClick={onClose} />
      {loading && <Spinner />}
      <canvas ref={canvasRef} />
    </div>
  
  );
};

OtherScene.propTypes = propTypes;
OtherScene.defaultProps = defaultProps;

export default OtherScene;

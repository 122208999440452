import React from "react";
import { func } from "prop-types";
import { Card, CloseButton } from "../../components";
import { videos } from "../../duck";

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const VideoScene = ({ onClose }) => {
  return (
    <div className="scene">
      <CloseButton onClick={onClose} />
      {videos.map(video => <Card key={video.key} model={video} video />)}
    </div>
  )
};

VideoScene.propTypes = propTypes;
VideoScene.defaultProps = defaultProps;

export default VideoScene;
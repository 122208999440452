import React, { Component, createRef } from "react";
import { Engine } from "babylonjs";
import "babylonjs-loaders";
import { func, object, bool } from "prop-types";

class BabylonScene extends Component {
  canvasRef = createRef();

  componentDidMount() {
    const { engineOptions, adaptToDeviceRatio, onMount } = this.props;

    this.engine = new Engine(
      this.canvasRef.current,
      true,
      engineOptions,
      adaptToDeviceRatio,
    );

    if (typeof onMount === "function") {
      onMount({ engine: this.engine, canvas: this.canvasRef.current });
    }

    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    if (this.engine) {
      this.engine.dispose();
    }
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    if (this.engine) {this.engine.resize();}
  };

  render() {
    return (
      <canvas
        ref={this.canvasRef}
        style={{ display: "block", width: "100%", height: "100%" }}
      />
    );
  }
}

BabylonScene.defaultProps = {
  engineOptions: {},
  adaptToDeviceRatio: false,
};

BabylonScene.propTypes = {
  onMount: func.isRequired,
  engineOptions: object,
  adaptToDeviceRatio: bool,
};

export default BabylonScene;

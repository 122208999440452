import React from "react";
import { SceneLoader, Vector3, Color3 } from "babylonjs";

import { Babylon, Camera } from "../babylon";

const NasaLaunchPad = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load(
        "assets/scenes/nasa_launch_pad/",
        "mlp.gltf",
        engine,
        scene => {
          scene.createDefaultLight(true);
          scene.createDefaultEnvironment({
            createSkybox: false,
            groundColor: new Color3(0, 0, 0),
            groundOpacity: 0.7,
          });
          const camera = new Camera(
            2.5,
            1,
            30,
            new Vector3.Zero(),
            5,
            50,
            scene,
          );
          camera.attachControl(canvas, true);
          engine.runRenderLoop(() => scene.render());
        },
      );
    }}
  />
);

export default NasaLaunchPad;

import React from "react";
import { SceneLoader, Vector3 } from "babylonjs";

import { Babylon, Camera } from "../babylon";

const PlantCell = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load(
        "assets/scenes/plant_cell/",
        "plant_cell.gltf",
        engine,
        scene => {
          scene.createDefaultLight(true);
          const camera = new Camera(
            2.4,
            0.8,
            35,
            new Vector3.Zero(),
            35,
            100,
            scene,
          );
          camera.attachControl(canvas, true);

          engine.runRenderLoop(() => scene.render());
        },
      );
    }}
  />
);

export default PlantCell;

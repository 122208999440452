/* eslint-disable */

import React, { useState, useMemo, useCallback } from "react";
import { CloseButton } from "./components";
import {
  EdibleScene, MathScene, ChemistryScene, OtherScene, AnimatedScene, StaticScene, VideoScene, HotelScene,
  DishesScene
} from "./scenes";
import { CHEMISTRY_SCENE, EDIBLE_SCENE, MATH_SCENE, OTHER_SCENE, ANIMATED_SCENE, STATIC_SCENE, VIDEO_SCENE, HOTEL_SCENE, DISHES_SCENE } from "./duck";

const App = () => {
  const [scene, setScene] = useState(null);
  const handleCloseScene = useCallback((event) => {
    event.nativeEvent.stopImmediatePropagation();
    setScene(null);
  }, []);
  const currentScene = useMemo(() => {
    switch(scene) {
      case CHEMISTRY_SCENE:
        return <ChemistryScene onClose={handleCloseScene} />;
      case EDIBLE_SCENE:
        return <EdibleScene onClose={handleCloseScene} />;
      case MATH_SCENE:
        return <MathScene onClose={handleCloseScene} />;
      case OTHER_SCENE:
        return <OtherScene onClose={handleCloseScene} />;
      case ANIMATED_SCENE:
        return <AnimatedScene onClose={handleCloseScene} />;
      case STATIC_SCENE:
        return <StaticScene onClose={handleCloseScene} />;
      case VIDEO_SCENE:
        return <VideoScene onClose={handleCloseScene} />;
      case HOTEL_SCENE:
        return <HotelScene onClose={handleCloseScene} />;
      case DISHES_SCENE:
        return <DishesScene onClose={handleCloseScene} />;
      default:
        return null;
    }
  }, [scene]);
  return (
    <div className="appRoot">
      {currentScene || (
        <div className="buttonsContainer">
          <button onClick={() => setScene(HOTEL_SCENE)} className="btn drawBorder">Hotel</button>
          <button onClick={() => setScene(CHEMISTRY_SCENE)} className="btn drawBorder">Chemistry</button>
          <button onClick={() => setScene(MATH_SCENE)} className="btn drawBorder">Math</button>
          <button onClick={() => setScene(EDIBLE_SCENE)} className="btn drawBorder">Edible / Inedible</button>
          <button onClick={() => setScene(OTHER_SCENE)} className="btn drawBorder">Extras</button>
          <button onClick={() => setScene(DISHES_SCENE)} className="btn drawBorder">Dishes</button>
          <button onClick={() => setScene(ANIMATED_SCENE)} className="btn drawBorder">Animated 3D Models</button>
          <button onClick={() => setScene(STATIC_SCENE)} className="btn drawBorder">Static 3D Models</button>
          <button onClick={() => setScene(VIDEO_SCENE)} className="btn drawBorder">Videos</button>
        </div>
      )}
    </div>
  )
};

export default App;
import React from "react";
import { SceneLoader, Vector3 } from "babylonjs";

import { Babylon, Camera } from "../babylon";

const MountsthelenScene = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load(
        "assets/scenes/mount/",
        "mountsthelen.gltf",
        engine,
        scene => {
          scene.createDefaultLight(true);
          const camera = new Camera(
            1,
            1,
            1000,
            new Vector3.Zero(),
            500,
            6000,
            scene,
          );
          camera.attachControl(canvas, true);
          engine.runRenderLoop(() => scene.render());
        },
      );
    }}
  />
);

export default MountsthelenScene;

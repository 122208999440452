import React from "react";
import { SceneLoader, Vector3, Color3 } from "babylonjs";

import { Babylon, Camera } from "../babylon";

const SteamEngineScene = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load(
        "assets/scenes/steam_engine/",
        "steam_engine.gltf",
        engine,
        scene => {
          scene.createDefaultLight(true);
          scene.createDefaultEnvironment({
            createSkybox: false,
            groundColor: new Color3(0, 0, 0),
            groundOpacity: 0.7,
          });

          const camera = new Camera(
            -1,
            1,
            50,
            new Vector3.Zero(),
            25,
            150,
            scene,
          );
          camera.attachControl(canvas, true);

          engine.runRenderLoop(() => scene.render());
        },
      );
    }}
  />
);

export default SteamEngineScene;

import React from "react";
import { Color3, SceneLoader, Vector3 } from "babylonjs";

import { Babylon, Camera } from "../babylon";

const Colosseum = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load(
        "assets/scenes/colosseum/",
        "roman colosseum.gltf",
        engine,
        scene => {
          scene.createDefaultLight(true);
          scene.createDefaultEnvironment({
            createSkybox: false,
            groundColor: new Color3(0, 0, 0),
            groundOpacity: 0.7,
          });

          const camera = new Camera(
            3,
            1.15,
            1700,
            new Vector3.Zero(),
            10,
            2000,
            scene,
          );
          camera.attachControl(canvas, true);
          engine.runRenderLoop(() => scene.render());
        },
      );
    }}
  />
);

export default Colosseum;

import React from "react";
import { Color3, SceneLoader, Vector3 } from "babylonjs";

import { Babylon, Camera } from "../babylon";

const PicDumidiDossau = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load(
        "assets/scenes/picdumididossau/",
        "picdumididossau.babylon",
        engine,
        scene => {
          scene.createDefaultLight(true);
          scene.createDefaultEnvironment({
            createSkybox: false,
            groundColor: new Color3.White(),
            groundOpacity: 0.4,
          });

          const camera = new Camera(
            0,
            1,
            500,
            new Vector3.Zero(),
            100,
            1000,
            scene,
          );
          camera.attachControl(canvas, true);
          engine.runRenderLoop(() => scene.render());
        },
      );
    }}
  />
);

export default PicDumidiDossau;

import { Rectangle, TextBlock } from "babylonjs-gui";

const VISIBLE_OPACITY = 0.75;

class Tooltip extends Rectangle {
  constructor() {
    super();

    this.width = "150px";
    this.height = "30px";
    this.background = "black";
    this.color = "black";
    this.cornerRadius = 4;
    this.alpha = 0;

    this.textBlock = new TextBlock();
    this.textBlock.color = "white";
    this.addControl(this.textBlock);
  }

  get isShown() {
    return this.alpha === VISIBLE_OPACITY;
  }

  set text(newText) {
    this.textBlock.text = newText;
  }

  hide() {
    this.alpha = 0;
  }

  show() {
    this.alpha = VISIBLE_OPACITY;
  }
}

export default Tooltip;

import React from "react";
import { SceneLoader, Vector3 } from "babylonjs";

import { Babylon, Camera } from "../babylon";

const Ant = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load("assets/scenes/ant/", "ant.gltf", engine, scene => {
        scene.createDefaultLight(true);
        const camera = new Camera(2.5, 1, 3, new Vector3.Zero(), 3, 20, scene);
        camera.attachControl(canvas, true);

        engine.runRenderLoop(() => scene.render());
      });
    }}
  />
);

export default Ant;

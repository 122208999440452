const { TextureLoader, FontLoader, GLTFLoader } = window.THREE;

const textureLoader = new TextureLoader();
const fontLoader = new FontLoader();
const gltfLoader = new GLTFLoader();

export const loadTexture = (item, onProgress = () => {}) => new Promise((resolve, reject) => {
  textureLoader.load(item.url, texture => resolve({ texture, data: item }), onProgress, reject);
});

export const loadFont = (url, onProgress = () => {}) => new Promise((resolve, reject) => {
  fontLoader.load(url, resolve, onProgress, reject);
});

export const loadTextures = (items) => Promise.all(items.map(item => loadTexture(item)));

export const loadGltf = ({ url, item }, onProgress = () => {}) => new Promise((resolve, reject) => {
  gltfLoader.load(url, texture => resolve({ texture, data: item }), onProgress, reject);
});

import React, { PureComponent, createRef } from "react";
import {
  arrayOf,
  shape,
  oneOf,
  string,
  bool,
  number,
  oneOfType,
} from "prop-types";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-vr";
import "./index.css";

class Video extends PureComponent {
  videoRef = createRef();
  
  componentDidMount() {
    const { vr, ...videoJsOptions } = this.props;
    const options = {
      controlBar: {
        subsCapsButton: false,
        progressControl: {
          seekBar: {
            loadProgressBar: false,
          },
        },
      },
      controls: true,
      preload: "none",
      ...videoJsOptions,
    };
    
    this.player = videojs(this.videoRef.current, options, () => {
      if (vr) {this.player.vr({ projection: "360" });}
    });
  }
  
  componentWillUnmount() {
    if (this.player) {this.player.dispose();}
  }
  
  render() {
    const { vr, width, height } = this.props;
    
    return (
      <div data-vjs-player data-vr={vr} style={{ width, height }}>
        <video ref={this.videoRef} className="video-js" crossOrigin="anonymous">
          <track src="" kind="captions" srcLang="en" label="English" />
        </video>
      </div>
    );
  }
}

Video.defaultProps = {
  poster: null,
  vr: false,
  width: "100%",
  height: "100%",
};

Video.propTypes = {
  sources: arrayOf(
    shape({
      src: string.isRequired,
      type: oneOf(["video/mp4"]).isRequired,
    }),
  ).isRequired,
  poster: string,
  vr: bool,
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
};

export default Video;

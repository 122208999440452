import React, { useEffect, useRef, useState } from "react";
import { func } from "prop-types";
import envMapImage from "../../assets/envmap.png";
import { Spinner, CloseButton } from "../../components";
import { dishes } from "../../duck";
import {initializeArToolkit, initializeRenderer, getMarker, loadGltf, loadTexture} from "../../utils";

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const {
  Camera,
  Scene,
  Group,
  DirectionalLight,
  AmbientLight,
  DoubleSide,
  EquirectangularReflectionMapping,
  AnimationMixer,
  Clock,
  LoopOnce,
} = window.THREE;

const DishesScene = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const canvasRef = useRef();
  
  useEffect(() => {
    const clock = new Clock();
    let requestId = null;
    let animationTarget = null;
    const renderer = initializeRenderer(canvasRef.current);
    renderer.gammaOutput = true;
    renderer.gammaFactor = 2.2;
    const scene = new Scene();
    const camera = new Camera();
    const directionalLight = new DirectionalLight();
    const ambientLight = new AmbientLight(0xffffff, 0.45);
    scene.add(camera, directionalLight, ambientLight);
    const { arToolkitContext, updateToolkitContent, destroy } = initializeArToolkit(renderer, camera);
    
    Promise.all([
      Promise.all(dishes.map(item => {
        const group = new Group();
        scene.add(group);
        getMarker(arToolkitContext, group, {type: "barcode", barcodeValue: item.key});
        return loadGltf({ url: item.url, item: { ...item, group }})
      })),
      loadTexture({ url: envMapImage })
    ]).then(([models, { texture: envMapTexture }]) => {
      models.map(({ texture, data }) => {
        if (texture.animations.length) {
          animationTarget = data.group;
          animationTarget.animationMixer = new AnimationMixer(texture.scene);
          animationTarget.clip = animationTarget.animationMixer.clipAction(texture.animations[0]);
          animationTarget.clip.loop = LoopOnce;
          animationTarget.clip.clampWhenFinished = true;
        }
        
        if (data.doubleSide) {
          const child = texture.scene.getObjectByName("plate");
          child.material.side = DoubleSide;
        } else {
          const child = texture.scene.getObjectByName("cocktail");
          const envMap = envMapTexture;
          envMap.needsUpdate = true;
          envMap.mapping = EquirectangularReflectionMapping;
          child.material.envMap = envMap;
        }
        texture.scene.scale.set(data.scale, data.scale, data.scale);
        return data.group.add(texture.scene);
      });
      setLoading(false);
    });
  
    const renderSceneObjects = () => {
      if (animationTarget) {
        if (animationTarget.visible) {
          if (!animationTarget.animationStarted) {
            animationTarget.animationStarted = true;
            animationTarget.clip.play();
          }
          animationTarget.animationMixer.update(clock.getDelta());
        } else {
          animationTarget.animationStarted = false;
          animationTarget.clip.reset();
          animationTarget.clip.stop();
        }
      }
    };
  
    const animate = () => {
      requestId = requestAnimationFrame(animate);
      renderer.render(scene, camera);
      updateToolkitContent();
      renderSceneObjects();
    };
  
    requestId = requestAnimationFrame(animate);
    
    return () => {
      renderer.dispose();
      destroy();
      if (requestId) {
        cancelAnimationFrame(requestId);
      }
    };
  }, []);
  return (
    <div>
      <CloseButton onClick={onClose} />
      {loading && <Spinner />}
      <canvas ref={canvasRef} />
    </div>
  
  );
};

DishesScene.propTypes = propTypes;
DishesScene.defaultProps = defaultProps;

export default DishesScene;

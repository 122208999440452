import React from "react";
import {
  Color3,
  SceneLoader,
  StandardMaterial,
  Vector3,
  GlowLayer,
} from "babylonjs";

import { Babylon, Camera } from "../babylon";

const ElectricCircuitScene = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load(
        "assets/scenes/electric_circuit/",
        "lamp.gltf",
        engine,
        scene => {
          scene.createDefaultLight(true);
          scene.createDefaultEnvironment({ createSkybox: false });

          const gl = new GlowLayer("glow", scene, {
            mainTextureFixedSize: 256,
            blurKernelSize: 32,
          });

          gl.intensity = 0.75;

          const glassMesh = scene.meshes.find(
            mesh => mesh.name.indexOf("glass") !== -1,
          );
          const filamentMesh = scene.meshes.find(
            mesh => mesh.name.indexOf("filament") !== -1,
          );
          const glassMaterial = new StandardMaterial("myMaterial", scene);
          const filamentMaterial = new StandardMaterial("myMaterial", scene);
          const yellowColor = new Color3(1, 1, 0.25);
          const orangeColor = new Color3(1, 0.65, 0);

          glassMaterial.emissiveColor = yellowColor;
          filamentMaterial.emissiveColor = orangeColor;

          glassMesh.material = glassMaterial;
          filamentMesh.material = filamentMaterial;

          const camera = new Camera(0, 1, 15, new Vector3.Zero(), 5, 40, scene);
          camera.attachControl(canvas, true);
          engine.runRenderLoop(() => scene.render());
        },
      );
    }}
  />
);

export default ElectricCircuitScene;

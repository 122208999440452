import apple from "../assets/apple.png";
import cardbox from "../assets/cardbox.png";
import incorrect from "../assets/incorrect.png";
import correct from "../assets/correct.png";
import question from "../assets/question.png";
import iceCream from "../assets/ice-cream.png";
import cone from "../assets/cone.png";
import brick from "../assets/brick.png";
import juice from "../assets/juice.png";
import candle from "../assets/candle.png";
import cake from "../assets/cake.png";
import heart from "../assets/heart1.jpg";
import galaxy from "../assets/galaxy.jpg";
import shark from "../assets/shark_idle.png";
import endeavour from "../assets/endeavour_scr.jpg";
import steamEngine from "../assets/steam_engine_scr.png";
import spider from "../assets/spider_scr.jpg";
import ant from "../assets/ant_scr.jpg";
import plantCell from "../assets/plant_cell_scr.jpg";
import electricCircuit from "../assets/electric_circuit_scr.jpg";
import mountsthelen from "../assets/mountsthelen_scr.jpg";
import colosseum from "../assets/roman_colosseum.jpg";
import nasaLaunchPad from "../assets/mlp_scr.jpg";
import houseFly from "../assets/housefly_scr.jpg";
import picDumidiDossau from "../assets/picdumididossau.jpg";
import video1preview from "../assets/wind_impact_on_soil.jpg";
import video2preview from "../assets/water_impact_on_soil.jpg";
import video3preview from "../assets/msls.jpg";
import video4preview from "../assets/substance_soluble.jpg";
import video5preview from "../assets/substance_magnetic.jpg"
import video6preview from "../assets/rat.jpg";
import video7preview from "../assets/sound_water_energy.jpg";
import video8preview from "../assets/hot_water_cooling.jpg";
import video9preview from "../assets/ngss.jpg";
import video10preview from "../assets/hurricane.jpg";
import video11preview from "../assets/water_cycle.jpg";
import video12preview from "../assets/ngss_snow_storm.jpg";
import video13preview from "../assets/storm_lightning_damage.jpg";
import video1 from "../assets/fan_sand.mp4";
import video2 from "../assets/water_sand.mp4";
import video3 from "../assets/ms-ls.mp4";
import video4 from "../assets/substance_soluble_3in1.mp4";
import video5 from "../assets/substance_magnetic_3in1.mp4";
import video6 from "../assets/rat3.mp4";
import video7 from "../assets/sound_water_energy.mp4";
import video8 from "../assets/hot_water_cooling.mp4";
import video9 from "../assets/ngss.mp4";
import video10 from "../assets/hurricane.mp4";
import video11 from "../assets/water_cycle.mp4";
import video12 from "../assets/ngss_snow_storm.mp4";
import video13 from "../assets/storm_lightning_damage.mp4";

export const IDLE = "idle";
export const EDIBLE = "edible";
export const NOEDIBLE = "noEdible";

export const MATH_SCENE = "math";
export const EDIBLE_SCENE = "edible";
export const CHEMISTRY_SCENE = "chemistry";
export const OTHER_SCENE = "other";
export const ANIMATED_SCENE = "animated";
export const STATIC_SCENE = "static";
export const VIDEO_SCENE = "video";
export const HOTEL_SCENE = "hotel";
export const DISHES_SCENE = "dishes";

export const edibleItems = [
  {
    id: 1,
    root: true,
    modelUrl: "assets/smile.glb",
    position: 2,
    scale: 0.5,
    planes: [
      {
        url: incorrect,
        withHelper: false,
        visible: false,
        state: NOEDIBLE,
      },
      {
        url: correct,
        withHelper: false,
        visible: false,
        state: EDIBLE,
      },
      {
        url: question,
        withHelper: true,
        visible: true,
        state: IDLE,
      }
    ]
  },
  {
    id: 2,
    root: false,
    modelUrl: "assets/apple.glb",
    edible: true,
    position: 1.5,
    scale: 0.5,
    planes: [{
      url: apple,
      withHelper: true,
      visible: true,
    }],
  },
  {
    id: 3,
    root: false,
    modelUrl: "assets/cardbox.glb",
    edible: false,
    position: 1,
    scale: 0.06,
    planes: [{
      url: cardbox,
      withHelper: true,
      visible: true,
    }],
  },{
    id: 4,
    root: false,
    modelUrl: "assets/icecream.glb",
    edible: true,
    position: 2,
    scale: 0.2,
    planes: [{
      url: iceCream,
      withHelper: true,
      visible: true,
    }],
  },
  {
    id: 5,
    root: false,
    modelUrl: "assets/cone.glb",
    edible: false,
    position: 1,
    scale: 0.2,
    planes: [{
      url: cone,
      withHelper: true,
      visible: true,
    }],
  },
  {
    id: 6,
    root: false,
    modelUrl: "assets/brick.glb",
    edible: false,
    position: 0.75,
    scale: 0.15,
    planes: [{
      url: brick,
      withHelper: true,
      visible: true,
    }],
  },
  {
    id: 7,
    root: false,
    modelUrl: "assets/juice.glb",
    edible: true,
    position: 2,
    scale: 0.15,
    planes: [{
      url: juice,
      withHelper: true,
      visible: true,
    }],
  },
  {
    id: 8,
    root: false,
    modelUrl: "assets/candle.glb",
    edible: false,
    position: 1,
    scale: 0.3,
    planes: [{
      url: candle,
      withHelper: true,
      visible: true,
    }],
  },
  {
    id: 9,
    root: false,
    modelUrl: "assets/cake.glb",
    edible: true,
    position: 1,
    scale: 0.15,
    planes: [{
      url: cake,
      withHelper: true,
      visible: true,
    }],
  },
];

export const models = [
  {
    id: 1,
    url: "assets/ant.glb",
    scale: 1,
  },
  {
    id: 2,
    url: "assets/endeavour.glb",
    scale: 0.2,
  },
  {
    id: 3,
    url: "assets/spider.glb",
    scale: 1,
  },
  {
    id: 4,
    url: "assets/steam_engine.glb",
    scale: 0.2,
  },
  {
    id: 5,
    url: "assets/test_tube.glb",
    scale: 0.2,
  },
  {
    id: 6,
    url: "assets/colosseum.glb",
    scale: 0.0025,
  },
  {
    id: 7,
    url: "assets/turbine.glb",
    scale: 0.03,
  },
  {
    id: 8,
    url: "assets/pic.glb",
    scale: 0.008,
  },
  {
    id: 9,
    url: "assets/veinartery.glb",
    scale: 0.15,
  }
];

export const models3dAnimated = [
  { key: "1", title: "Heart", image: heart },
  { key: "2", title: "Galaxy", image: galaxy },
  { key: "3", title: "Shark", image: shark },
  { key: "4", title: "Endeavour", image: endeavour },
  { key: "5", title: "Spider", image: spider },
  { key: "6", title: "Ant", image: ant },
  { key: "7", title: "Plant Cell", image: plantCell },
  { key: "8", title: "Electric Circuit", image: electricCircuit },
  { key: "9", title: "Steam Engine", image: steamEngine },
];

export const models3dStatic = [
  { key: "1", title: "Mountsthelen", image: mountsthelen },
  { key: "2", title: "Roman Colosseum", image: colosseum },
  { key: "3", title: "NASA Launch Pad", image: nasaLaunchPad },
  { key: "4", title: "House Fly", image: houseFly },
  { key: "5", title: "Pic Dumidi Dossau", image: picDumidiDossau },
];

export const videos = [
  { key: "1", title: "Wind impact on soil", image: video1preview, url: video1 },
  {
    key: "2",
    title: "Water impact on soil",
    image: video2preview,
    url: video2,
  },
  { key: "3", title: "MS LS", image: video3preview, url: video3 },
  { key: "4", title: "Substance soluble", image: video4preview, url: video4 },
  { key: "5", title: "Substance magnetic", image: video5preview, url: video5 },
  { key: "6", title: "Rat3", image: video6preview, url: video6 },
  { key: "7", title: "Sound water energy", image: video7preview, url: video7 },
  { key: "8", title: "Hot water cooling", image: video8preview, url: video8 },
  { key: "9", title: "NGSS", image: video9preview, url: video9 },
  { key: "10", title: "Hurricane", image: video10preview, url: video10 },
  {
    key: "11",
    title: "Water cycle",
    image: video11preview,
    url: video11,
  },
  {
    key: "12",
    title: "NGSS snow storm",
    image: video12preview,
    url: video12,
  },
  {
    key: "13",
    title: "Storm lightning damage",
    image: video13preview,
    url: video13,
  },
];

export const hotelItems = [
  {
    key: 1,
    name: "bedroom",
    url: "assets/bedroom.glb",
    scale: 0.15,
    root: false,
  },
  {
    key: 2,
    name: "aquapark",
    url: "assets/waterpark.glb",
    scale: 0.35,
    root: false,
  },
  {
    key: 3,
    name: "riches",
    url: "assets/riches.glb",
    scale: 0.2,
    root: false,
  },
  {
    key: 4,
    name: "hotel",
    url: "assets/atlantis.glb",
    scale: 0.25,
    root: true,
  }
];

export const dishes = [
  {
    key: 1,
    name: "cocktail",
    url: "assets/glass.glb",
    scale: 0.25,
    doubleSide: false,
  },
  {
    key: 2,
    name: "sweet",
    url: "assets/sweet.glb",
    scale: 1,
    doubleSide: true,
  },
];

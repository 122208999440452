import React from "react";
import { SceneLoader, Vector3 } from "babylonjs";
import { AdvancedDynamicTexture } from "babylonjs-gui";

import { Babylon, Camera, Panel, Button } from "../babylon";

const ANIMATIONS = { landing: 0, canadarm: 1 };

const EndeavourScene = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load(
        "assets/scenes/endeavour/",
        "endeavour.gltf",
        engine,
        scene => {
          scene.createDefaultLight(true);
          const camera = new Camera(
            -0.5,
            1,
            50,
            new Vector3.Zero(),
            20,
            150,
            scene,
          );
          camera.attachControl(canvas, true);

          let currentAnimation = ANIMATIONS.landing;

          const ui = AdvancedDynamicTexture.CreateFullscreenUI("UI");
          const panel = new Panel("Animations");

          const landing = new Button("landing", "Landing");
          landing.onPointerClickObservable.add(() => {
            scene.animationGroups[currentAnimation].reset();
            scene.animationGroups[currentAnimation].stop();
            scene.animationGroups[ANIMATIONS.landing].play(true);
            currentAnimation = ANIMATIONS.landing;
          });

          const canadarm = new Button("canadarm", "Canadarm");
          canadarm.onPointerClickObservable.add(() => {
            scene.animationGroups[currentAnimation].reset();
            scene.animationGroups[currentAnimation].stop();
            scene.animationGroups[ANIMATIONS.canadarm].play(true);
            currentAnimation = ANIMATIONS.canadarm;
          });

          panel.addControl(landing);
          panel.addControl(canadarm);
          ui.addControl(panel);

          engine.runRenderLoop(() => scene.render());
        },
      );
    }}
  />
);

export default EndeavourScene;

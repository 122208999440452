import React from "react";
import { func } from "prop-types";
import "./index.css";

const propTypes = {
  onClick: func,
};

const defaultProps = {
  onClick: () => {},
};

const CloseButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="closeButton"
    type="button"
  >
    X
  </button>
);

CloseButton.propTypes = propTypes;
CloseButton.defaultProps = defaultProps;

export default CloseButton;
import React from "react";
import { object, func, bool } from "prop-types";
import Video from "../video";
import "./index.css";

const propTypes = {
  model: object.isRequired,
  onClick: func,
  video: bool,
};

const defaultProps = {
  onClick: () => {},
  video: false,
};

const Card = ({ model, onClick, video }) => (
  <div
    className={video ? "card video" : "card"}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyDown={() => {}}
  >
    <span className="cardTitle">
      {model.title}
    </span>
    {video ? (
      <Video sources={[{ src: model.url, type: "video/mp4", preload: false }]} poster={model.image} />
    ) : (
      <div className="imageWrapper">
        <img src={model.image} alt={model.title} />
      </div>
    )}
  </div>
);

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
import { StackPanel, Control, Rectangle, TextBlock } from "babylonjs-gui";

class Panel extends StackPanel {
  constructor(title) {
    super();

    this.width = "150px";
    this.background = "black";
    this.color = "black";
    this.cornerRadius = 4;
    this.alpha = 0.75;
    this.left = "3%";
    this.top = "5%";
    this.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LET;
    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    const titleBlock = new Rectangle();
    titleBlock.height = "30px";
    titleBlock.color = "white";
    titleBlock.thickness = 0;

    const titleText = new TextBlock();
    titleText.color = "white";
    titleText.fontSize = 16;
    titleText.text = title;
    titleBlock.addControl(titleText);
    this.addControl(titleBlock);
  }
}

export default Panel;

import React from "react";
import { SceneLoader, Vector3, AnimationPropertiesOverride } from "babylonjs";
import { AdvancedDynamicTexture } from "babylonjs-gui";

import { Babylon, Camera, Panel, Button } from "../babylon";

const ANIMATIONS = { idle: 0, attack: 1 };

const SharkScene = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load("assets/scenes/shark/", "shark.gltf", engine, scene => {
        const {
          meshes: [, mesh],
          skeletons: [skeleton],
        } = scene;

        scene.createDefaultLight(true);
        const camera = new Camera(
          2,
          1.5,
          100,
          new Vector3.Zero(),
          50,
          300,
          scene,
        );
        camera.attachControl(canvas, true);

        skeleton.animationPropertiesOverride = new AnimationPropertiesOverride();
        skeleton.animationPropertiesOverride.enableBlending = true;
        mesh.useVertexColors = false;

        let currentAnimation = ANIMATIONS.idle;

        const ui = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        const panel = new Panel("Animations");

        const idle = new Button("idle", "Idle");
        idle.onPointerClickObservable.add(() => {
          scene.animationGroups[currentAnimation].stop();
          scene.animationGroups[ANIMATIONS.idle].play(true);
          currentAnimation = ANIMATIONS.idle;
        });

        const attack = new Button("attack", "Attack");
        attack.onPointerClickObservable.add(() => {
          scene.animationGroups[currentAnimation].stop();
          scene.animationGroups[ANIMATIONS.attack].play(true);
          currentAnimation = ANIMATIONS.attack;
        });

        panel.addControl(idle);
        panel.addControl(attack);
        ui.addControl(panel);

        engine.runRenderLoop(() => scene.render());
      });
    }}
  />
);

export default SharkScene;

import React from "react";
import { SceneLoader, Vector3 } from "babylonjs";

import { Babylon, Camera } from "../babylon";

const GalaxyScene = () => (
  <Babylon
    onMount={({ engine, canvas }) => {
      SceneLoader.Load(
        "assets/scenes/galaxy/",
        "galaxy.babylon",
        engine,
        scene => {
          const camera = new Camera(
            1,
            1.5,
            200,
            new Vector3.Zero(),
            50,
            1000,
            scene,
          );
          camera.attachControl(canvas, true);
          engine.runRenderLoop(() => scene.render());
        },
      );
    }}
  />
);

export default GalaxyScene;

/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { func } from "prop-types";
import { edibleItems, IDLE, NOEDIBLE, EDIBLE } from "../../duck";
import {CloseButton, Spinner} from "../../components";
import {initializeArToolkit, initializeRenderer, getMarker, loadGltf, loadTextures} from "../../utils";

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const {
  Camera,
  Scene,
  Group,
  Mesh,
  MeshBasicMaterial,
  Vector3,
  DirectionalLight,
  PlaneGeometry,
  SphereGeometry,
  MeshStandardMaterial,
  Box3,
  BoxHelper,
  AmbientLight,
  TextGeometry,
  MeshPhongMaterial,
  GeometryUtils,
  AnimationMixer,
  Clock,
  LoopRepeat,
} = window.THREE;

const EdibleScene = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const canvasRef = useRef();
  const animations = useRef({});
  
  useEffect(() => {
    let requestId = null;
    const store = {
      collisionObjectId: null,
      state: IDLE,
      loading: true,
    };
    const clock = new Clock();
    const renderer = initializeRenderer(canvasRef.current);
    const scene = new Scene();
    const camera = new Camera();
    const directionalLight = new DirectionalLight();
    const ambientLight = new AmbientLight();
    const imageGeometry = new PlaneGeometry(1, 1, 4, 4);
    scene.add(camera, directionalLight, ambientLight);
    const { arToolkitContext, updateToolkitContent, destroy } = initializeArToolkit(renderer, camera);
    let arData = edibleItems;
    
    Promise.all(arData.map(item => {
      const group = new Group();
      group.edible = item.edible;
      group.root = item.root;
      scene.add(group);
      getMarker(arToolkitContext, group, {type: "barcode", barcodeValue: item.id});
      return loadGltf({ url: item.modelUrl, item: { ...item, group }})
    })).then(models => {
      arData = models.map(model => model.data);
      return Promise.all(models.map(({ texture, data }) => {
        if (data.root) {
          texture.scene.rotation.x = -Math.PI / 5;
          const animationMixer = new AnimationMixer(texture.scene);
          const clips = texture.animations;
          texture.scene.children[0].children[0].children.forEach(child => child.morphTargetInfluences[0] = 0);
          data.group.animationMixer = animationMixer;
          data.group.animations = {};
          clips.forEach(clip => {
            const clipAction = animationMixer.clipAction(clip);
            clipAction.loop = LoopRepeat;
            clipAction.timeScale = 0.5;
            data.group.animations[clip.name] = clipAction;
          });
        }
        texture.scene.position.set(0, data.position, 0);
        texture.scene.scale.set(data.scale, data.scale, data.scale);
        data.group.add(texture.scene);
        return loadTextures(data.planes)
      }));
    }).then((planes) => {
      planes.forEach((textures, index) => {
        textures.forEach(({ texture, data }) => {
          const group = arData[index].group;
          const material = new MeshBasicMaterial({ map: texture });
          const mesh = new Mesh(imageGeometry, material);
          mesh.rotation.x = -Math.PI / 2;
          mesh.scale.set(1.8, 1.8, 1.8);
          mesh.visible = data.visible;
          mesh.arState = data.state;
          if (data.withHelper) {
            const helper = new BoxHelper(mesh, 0x00ff00);
            helper.visible = false;
            group.helper = helper;
            group.collisionBox = new Box3();
            scene.add(helper);
          }
          group.add(mesh);
        })
      });
      store.loading = false;
      setLoading(false);
    });
    
    const checkObjectsIntersection = (currentGroup, targetGroup) => {
      const intersects = currentGroup.collisionBox.intersectsBox(targetGroup.collisionBox);
      if (intersects) {
        store.collisionObjectId = currentGroup.id;
        if (currentGroup.edible && !targetGroup.animations.yes.isRunning()) {
          targetGroup.animations.no.stop();
          targetGroup.animations.yes.play();
          store.state = EDIBLE;
        } else if (!currentGroup.edible && !targetGroup.animations.no.isRunning()) {
          targetGroup.animations.no.play();
          targetGroup.animations.yes.stop();
          store.state = NOEDIBLE;
        }
      }
    };
    
    const renderSceneObjects = () => {
      if (store.loading) return;
      const root = arData.find(item => item.root).group;
      const itemsWithoutRoot = arData.filter(item => !item.root);
      itemsWithoutRoot.forEach(({ group }) => {
        if (group && group.visible && group.helper) {
          group.helper.update();
          group.collisionBox.setFromObject(group.helper);
        }
      });
      
      if (root.visible) {
        root.helper.update();
        root.collisionBox.setFromObject(root.helper);
        root.children.forEach(child => {
          child.visible = store.state === child.arState || child.arState === undefined;
        });
        if (store.collisionObjectId) {
          const collisionTarget = scene.children.find(child => child.id === store.collisionObjectId);
          if (collisionTarget.visible) {
            const intersects = collisionTarget.collisionBox.intersectsBox(root.collisionBox);
            if (intersects) {
              store.collisionObjectId = collisionTarget.id;
              if (collisionTarget.edible && !root.animations.yes.isRunning()) {
                root.animations.no.stop();
                root.animations.yes.play();
                store.state = EDIBLE;
              } else if (!collisionTarget.edible && !root.animations.no.isRunning()) {
                root.animations.no.play();
                root.animations.yes.stop();
                store.state = NOEDIBLE;
              }
              return;
            } else if (!intersects && collisionTarget.edible && root.animations.yes.isRunning()) {
              root.animations.yes.stop();
              store.state = IDLE;
              store.collisionObjectId = null;
            } else if (!intersects && !collisionTarget.edible && root.animations.no.isRunning()) {
              root.animations.no.stop();
              store.state = IDLE;
              store.collisionObjectId = null;
            }
            root.children.forEach(child => {
              child.visible = store.state === child.arState || child.arState === undefined;
            });
          } else if (!collisionTarget.visible) {
            root.animations[collisionTarget.edible ? "yes" : "no"].stop();
          }
        }
        store.collisionObjectId = null;
        store.state = IDLE;
        itemsWithoutRoot.forEach(({ group }) => {
          if (group.visible) {
            checkObjectsIntersection(group, root);
          }
        });
      } else {
        store.collisionObjectId = null;
        store.arState = IDLE;
        root.children.forEach(child => {
          child.visible = store.state === child.arState || child.arState === undefined;
        });
      }
    };
    
    const animate = () => {
      requestId = requestAnimationFrame(animate);
      renderer.render(scene, camera);
      updateToolkitContent();
      renderSceneObjects();
      const root = scene.children.find(child => child.root);
      if (root && root.animationMixer) {
        root.animationMixer.update(clock.getDelta());
      }
    };
    
    requestId = requestAnimationFrame(animate);
    
    return () => {
      renderer.dispose();
      destroy();
      if (requestId) {
        cancelAnimationFrame(requestId);
      }
    };
  }, []);
  return (
    <div>
      <CloseButton onClick={onClose} />
      {loading && <Spinner />}
      <canvas ref={canvasRef} />
    </div>
  );
};

EdibleScene.propTypes = propTypes;
EdibleScene.defaultProps = defaultProps;

export default EdibleScene;

import React, { Component } from "react";
import { SceneLoader, Vector3 } from "babylonjs";
import { AdvancedDynamicTexture } from "babylonjs-gui";

import { Babylon, Camera, Tooltip, Point } from "../babylon";

class HeartScene extends Component {
  prepareTooltip = (number, mesh, linkOffsetX = 95) => {
    this.tooltip.text = `Heart item: ${number}`;
    this.tooltip.linkWithMesh(mesh);
    this.tooltip.linkOffsetX = linkOffsetX;
  };

  render() {
    return (
      <Babylon
        onMount={({ engine, canvas }) => {
          SceneLoader.Load(
            "assets/scenes/heart/",
            "heart.babylon",
            engine,
            scene => {
              const camera = new Camera(
                1,
                1.5,
                50,
                new Vector3.Zero(),
                10,
                100,
                scene,
              );
              camera.attachControl(canvas, true);
              scene.createDefaultLight(true);

              const ui = AdvancedDynamicTexture.CreateFullscreenUI("UI");
              this.tooltip = new Tooltip();
              ui.addControl(this.tooltip);
              
              // eslint-disable-next-line
              scene.onPointerDown = () => {
                if (this.tooltip.isShown) {this.tooltip.hide();}
              };

              const heart = scene.meshes[0];
              const heartBones = scene.skeletons[0].bones;
              const points = [
                {
                  bone: heartBones[20],
                  position: new Vector3(0.3, 0.25, 1),
                  target: new Vector3(20, 7.5, 2.5),
                },
                {
                  bone: heartBones[6],
                  position: new Vector3(-0.5, 0, -0.5),
                  target: new Vector3(8.5, 11.7, -21.6),
                },
                {
                  bone: heartBones[14],
                  position: new Vector3(1, 0, 0),
                  target: new Vector3(7, -13.5, 22.5),
                },
              ];

              for (let i = 0; i < points.length; i += 1) {
                const point = new Point(
                  i + 1,
                  {
                    position: points[i].position,
                    bone: points[i].bone,
                    boneMesh: heart,
                  },
                  scene,
                );
                point.onPointerClickObservable.add(() => {
                  this.prepareTooltip(i + 1, point.mesh);
                  this.tooltip.show();
                  camera.animateToTarget(points[i].target);
                });

                ui.addControl(point);
                point.linkWithPointMesh();
              }
              engine.runRenderLoop(() => scene.render());
            },
          );
        }}
      />
    );
  }
}

export default HeartScene;

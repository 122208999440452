/* eslint-disable */
import React, { useEffect, useRef, useState, useCallback } from "react";
import { func } from "prop-types";
import {initializeArToolkit, initializeRenderer, getMarker, loadTexture, loadGltf} from "../../utils";
import { CloseButton, Spinner, BackButton } from "../../components";
import { hotelItems } from "../../duck";

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const {
  PerspectiveCamera,
  Scene,
  Group,
  Mesh,
  MeshBasicMaterial,
  DirectionalLight,
  PlaneGeometry,
  SphereGeometry,
  MeshStandardMaterial,
  BoxHelper,
  AnimationMixer,
  LoopRepeat,
  AmbientLight,
  Clock,
} = window.THREE;

const HotelScene = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [previewItem, setPreviewItem] = useState(null);
  const canvasRef = useRef();
  const markerRef = useRef();
  
  const handleBackButtonClick = useCallback((event) => {
    event.nativeEvent.stopImmediatePropagation();
    const hotel = markerRef.current.getObjectByName("hotel");
    const currentItem = markerRef.current.getObjectByName(previewItem);
    hotel.visible = true;
    currentItem.visible = false;
    setPreviewItem(null);
  }, [markerRef, previewItem]);
  
  const handlePreview = useCallback((previewName) => () => {
    const hotel = markerRef.current.getObjectByName("hotel");
    const currentItem = markerRef.current.getObjectByName(previewName);
    hotel.visible = false;
    currentItem.visible = true;
    setPreviewItem(previewName);
  }, [markerRef]);
  
  useEffect(() => {
    let requestId = null;
    const clock = new Clock();
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    const renderer = initializeRenderer(canvasRef.current);
    renderer.capabilities.logarithmicDepthBuffer = true;
    const scene = new Scene();
    const camera = new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.5, 500);
    const directionalLight = new DirectionalLight();
    const ambientLight = new AmbientLight(0xffffff, 0.75);
    const markerGroup = new Group();
    markerRef.current = markerGroup;
    scene.add(camera, directionalLight, ambientLight, markerGroup);
    const { arToolkitContext, updateToolkitContent, destroy } = initializeArToolkit(renderer, camera);
    getMarker(arToolkitContext, markerGroup, {type: "barcode", barcodeValue: 1});
    
    Promise
      .all(hotelItems.map(hotelItem => loadGltf({ url: hotelItem.url, item: { ...hotelItem, group: markerGroup }})))
      .then(models => {
        models.map(({ texture, data }) => {
          if (texture.animations.length) {
            const animationMixer = new AnimationMixer(texture.scene);
            const clipAction = animationMixer.clipAction(texture.animations[0]);
            clipAction.loop = LoopRepeat;
            texture.scene.animationMixer = animationMixer;
            texture.scene.clipAction = clipAction;
          }
          
          texture.scene.scale.set(data.scale, data.scale, data.scale);
          texture.scene.visible = data.root;
          texture.scene.name = data.name;
          if (!data.root) {
            texture.scene.rotation.y = -Math.PI;
          } else {
            const roomMarker = texture.scene.getObjectByName("pointersuite_marker");
            roomMarker.visible = false;
            const aquaMarker = texture.scene.getObjectByName("pointeraquapark_marker");
            aquaMarker.visible = false;
            const chamMarker = texture.scene.getObjectByName("pointerlost_cha_marker");
            chamMarker.visible = false;
          }
          data.group.add(texture.scene);
        });
        setLoading(false);
      });
    
    const renderSceneObjects = () => {
      scene.children.forEach(group => {
        group.children.forEach(child => {
          if (child.animationMixer) {
            if (child.visible) {
              if (!child.clipAction.isRunning()) {
                child.clipAction.play();
              }
              child.animationMixer.update(clock.getDelta());
            } else if (!child.visible && child.clipAction.isRunning()) {
              child.clipAction.stop();
            }
          }
        })
      })
    };
    
    const animate = () => {
      requestId = requestAnimationFrame(animate);
      renderer.render(scene, camera);
      updateToolkitContent();
      renderSceneObjects();
    };
    
    requestId = requestAnimationFrame(animate);
    
    return () => {
      renderer.dispose();
      destroy();
      if (requestId) {
        cancelAnimationFrame(requestId);
      }
    };
  }, []);
  
  return (
    <div>
      {loading ? <Spinner /> : previewItem ? (
        <BackButton onClick={handleBackButtonClick}/>
      ) : (
        <div style={{ display: "flex", position: "absolute", top: 20, left: 10, zIndex: 10 }}>
          <button
            style={{ marginRight: 5, padding: 5}}
            onClick={handlePreview("aquapark")}
          >
            Aquapark
          </button>
          <button
            style={{ marginRight: 5, padding: 5}}
            onClick={handlePreview("bedroom")}
          >
            President Suite
          </button>
          <button
            style={{ padding: 5 }}
            onClick={handlePreview("riches")}
          >
            Lost chambers
          </button>
        </div>
      )}
      <CloseButton onClick={onClose}/>
      <canvas ref={canvasRef}/>
    </div>
  );
};

HotelScene.propTypes = propTypes;
HotelScene.defaultProps = defaultProps;

export default HotelScene;

import React from "react";
import { func } from "prop-types";
import "./index.css";

const propTypes = {
  onClick: func,
};

const defaultProps = {
  onClick: () => {},
};

const BackButton = ({ onClick }) => (
  <button
    className="back-button"
    onClick={onClick}
    type="button"
  >
    Back
  </button>
);

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;

export default BackButton;

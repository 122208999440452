import {
  ArcRotateCamera,
  Animation,
  Vector3,
  EasingFunction,
  CubicEase,
} from "babylonjs";

class Camera extends ArcRotateCamera {
  constructor(
    alpha = 0,
    beta = 0,
    radius = 0,
    position = new Vector3.Zero(),
    lowerRadiusLimit = 10,
    upperRadiusLimit = 100,
    scene,
  ) {
    const camera = super("camera", alpha, beta, radius, position, scene);
    camera.lowerRadiusLimit = lowerRadiusLimit;
    camera.upperRadiusLimit = upperRadiusLimit;
    camera.pinchDeltaPercentage = 0.001;
    camera.wheelDeltaPercentage = 0.01;
  }

  animateToTarget(position, target) {
    const cameraScene = this.getScene();
    const ghostCam = new ArcRotateCamera(
      "ghostCam",
      0,
      0,
      0,
      position,
      cameraScene,
    );
    ghostCam.setTarget(target || new Vector3.Zero());
    cameraScene.cameras.pop();

    // // reseting the alpha and beta to a much simpler version
    // this.alpha = this.alpha % (Math.PI * 2);
    // this.beta = this.beta % (Math.PI * 2);
    // if (this.alpha < 0) {
    //     this.alpha += Math.PI * 2;
    // }
    // if (this.beta < 0) {
    //     this.beta += Math.PI * 2;
    // }

    // // used to find the shortest curve IE. if angle == 3PI/2 => take the -1PI/2 instead
    // let alpha = ghostCam.alpha;
    // if (Math.abs(this.alpha - alpha) > Math.PI) {
    //     alpha = ghostCam.alpha + (Math.PI * 2);
    // }

    // let beta = ghostCam.beta;
    // if (Math.abs(this.beta - beta) > Math.PI) {
    //     beta = ghostCam.beta + (Math.PI * 2);
    // }

    const radiusAnimation = new Animation(
      "camRadius",
      "radius",
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT,
    );
    const alphaAnimation = new Animation(
      "camAlpha",
      "alpha",
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT,
    );
    const betaAnimation = new Animation(
      "camBeta",
      "beta",
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT,
    );

    radiusAnimation.setKeys([
      { frame: 0, value: this.radius },
      { frame: 30, value: ghostCam.radius },
    ]);
    alphaAnimation.setKeys([
      { frame: 0, value: this.alpha },
      { frame: 30, value: ghostCam.alpha },
    ]);
    betaAnimation.setKeys([
      { frame: 0, value: this.beta },
      { frame: 30, value: ghostCam.beta },
    ]);

    const easingFunction = new CubicEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

    radiusAnimation.setEasingFunction(easingFunction);
    alphaAnimation.setEasingFunction(easingFunction);
    betaAnimation.setEasingFunction(easingFunction);

    this.animations.push(radiusAnimation);
    this.animations.push(alphaAnimation);
    this.animations.push(betaAnimation);

    cameraScene.beginAnimation(this, 0, 30, false, 1);
  }
}

export default Camera;

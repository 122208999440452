import cameraData from "../data/camera_para.dat";

const { Color, WebGLRenderer, Cache } = window.THREE;
const { ArMarkerControls, ArToolkitContext, ArToolkitSource } = window.THREEx;

export const initializeArToolkit = (renderer, camera) => {
  ArToolkitContext.baseURL = '../';
  const arToolkitSource = new ArToolkitSource({ sourceType : "webcam" });
  document.body.style.position = "fixed";
  
  // create an atToolkitContext
  const arToolkitContext = new ArToolkitContext({
    cameraParametersUrl: cameraData,
    // The hiro and kanji markers are monochrome
    detectionMode: "mono_and_matrix",
    maxDetectionRate: 60,
    // The two following settings adjusts the resolution. Higher is better (less flickering) but slower
    canvasWidth: 640,
    canvasHeight: 480,
  });
  
  const prevent = event => { event.preventDefault(); event.stopImmediatePropagation();};
  
  const onResize = () => {
    arToolkitSource.onResizeElement();
    arToolkitSource.copyElementSizeTo(renderer.domElement);
    if( arToolkitContext.arController !== null ){
      arToolkitSource.copyElementSizeTo(arToolkitContext.arController.canvas);
    }
  };
  
  arToolkitSource.init(() => {
    onResize();
  });
  
  window.addEventListener("resize", onResize);
  document.addEventListener("touchmove", prevent, { passive: false });
  
  arToolkitContext.init(() => {
    camera.projectionMatrix.copy(arToolkitContext.getProjectionMatrix());
  });
  
  // update artoolkit on every frame
  const updateToolkitContent = () => {
    if (!arToolkitSource.ready) {return;}
    arToolkitContext.update(arToolkitSource.domElement);
  };
  
  const destroy = () => {
    window.removeEventListener("resize", onResize);
    document.removeEventListener("touchmove", prevent);
    document.body.style.position = "unset";
    const video = document.querySelector("video");
    video.remove();
  };
  
  return { arToolkitContext, updateToolkitContent, destroy };
};

export const initializeRenderer =  (canvas) => {
  const renderer = new WebGLRenderer({ alpha: true, canvas, antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setClearColor(new Color("lightgrey"), 0);
  renderer.setSize(window.innerWidth, window.innerHeight, true);
  Cache.enabled = true;
  renderer.domElement.style.position = "fixed";
  renderer.domElement.style.top = "0px";
  renderer.domElement.style.left = "0px";
  renderer.domElement.style.zIndex = 2;
  return renderer;
};

export const getMarker = (arToolkitContext, markerRoot, options = {}) => new ArMarkerControls(arToolkitContext, markerRoot, options);
/* eslint-disable */
import React, {useEffect, useRef, useState} from "react";
import { func } from "prop-types";
import two from "../../assets/2.png";
import three from "../../assets/3.png";
import four from "../../assets/4.png";
import five from "../../assets/5.png";
import seven from "../../assets/7.png";
import eight from "../../assets/8.png";
import nine from "../../assets/9.png";
import {initializeArToolkit, initializeRenderer, getMarker, loadTextures, loadFont} from "../../utils";
import { Spinner, CloseButton } from "../../components";
import EdibleScene from "../edible/Edible";

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const {
  Camera,
  Scene,
  Group,
  Mesh,
  MeshBasicMaterial,
  Vector3,
  DirectionalLight,
  PlaneGeometry,
  SphereGeometry,
  MeshStandardMaterial,
  Box3,
  BoxHelper,
  AmbientLight,
  TextGeometry,
  MeshPhongMaterial,
  GeometryUtils,
} = window.THREE;

const MathScene = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const canvasRef = useRef();
  
  useEffect(() => {
    let requestId = null;
    const store = {
      collisionObjectId: null,
    };
    
    const renderer = initializeRenderer(canvasRef.current);
    const hydrogen1Box = new Box3();
    const hydrogen2Box = new Box3();
    const oxygenBox = new Box3();
    const scene = new Scene();
    const camera = new Camera();
    const directionalLight = new DirectionalLight();
    const ambientLight = new AmbientLight();
    const group5 = new Group();
    const group2 = new Group();
    const group3 = new Group();
    scene.add(camera, group2, group3, group5, directionalLight, ambientLight);
    
    const { arToolkitContext, updateToolkitContent, destroy } = initializeArToolkit(renderer, camera);
    getMarker(arToolkitContext, group3, {type: "barcode", barcodeValue: 4});
    getMarker(arToolkitContext, group5, {type: "barcode", barcodeValue: 5});
    getMarker(arToolkitContext, group2, {type: "barcode", barcodeValue: 1});
    const imageGeometry = new PlaneGeometry(1, 1, 4, 4);
    const textMaterial = new MeshPhongMaterial({ color: 0x1D4EBE });
    const textMaterialCorrect = new MeshPhongMaterial({ color: 0x0FB965 });
    const textMaterialWrong = new MeshPhongMaterial({ color: 0xD72412 });
    
    const checkObjectsIntersection = (currentGroup, targetGroup) => {
      const intersects = currentGroup.collisionBox.intersectsBox(targetGroup.collisionBox);
      currentGroup.getObjectByName("text").visible = !intersects;
      if (intersects) {
        store.collisionObjectId = currentGroup.id;
        if (targetGroup.currentValue !== targetGroup.defaultValue + currentGroup.value) {
          targetGroup.currentValue = targetGroup.defaultValue + currentGroup.value;
        }
        targetGroup.children.forEach(child => {
          child.visible = Boolean(child.arValue === targetGroup.currentValue);
        })
      } else {
        targetGroup.currentValue = targetGroup.defaultValue;
        targetGroup.children.forEach(child => {
          child.visible = Boolean(child.arValue === targetGroup.defaultValue);
        })
      }
    };
    
    const textures = [
      {
        url: two,
        groupValue: 2,
        meshValue: null,
        isRoot: false,
        group: group2,
        withHelper: true,
      },
      {
        url: three,
        groupValue: 3,
        meshValue: 3,
        isRoot: false,
        withHelper: true,
        group: group3,
      },
      {
        url: five,
        groupValue: 5,
        meshValue: 5,
        isRoot: true,
        withHelper: true,
        group: group5,
      },
      {
        url: seven,
        groupValue: null,
        meshValue: 7,
        isRoot: false,
        withHelper: false,
        group: group5,
        visible: false,
      },
      {
        url: eight,
        groupValue: null,
        meshValue: 8,
        isRoot: false,
        withHelper: false,
        group: group5,
        visible: false,
      },
    ];
    
    const textTextures = [
      {
        text: "5",
        material: textMaterial,
        value: 5,
        group: group5,
        visible: true,
      },
      {
        text: "3",
        material: textMaterial,
        value: 3,
        group: group3,
        visible: true,
      },
      {
        text: "2",
        material: textMaterial,
        value: 2,
        group: group2,
        visible: true,
      },
      {
        text: "7",
        material: textMaterialWrong,
        value: 7,
        group: group5,
        visible: false,
      },
      {
        text: "8",
        material: textMaterialCorrect,
        value: 8,
        group: group5,
        visible: false,
      },
    ];
    
    Promise.all([
      loadTextures(textures)
        .then((items) =>
          items.forEach(({ texture, data }) => {
            const material = new MeshBasicMaterial({ map: texture });
            const mesh = new Mesh(imageGeometry, material);
            mesh.rotation.x = -Math.PI / 2;
            mesh.scale.set(1.8, 1.8, 1.8);
            mesh.visible = data.visible;
            if (data.meshValue) {
              mesh.arValue = data.meshValue;
            }
            if (data.withHelper) {
              const helper = new BoxHelper(mesh, 0x00ff00);
              helper.visible = false;
              data.group.helper = helper;
              data.group.collisionBox = new Box3();
              scene.add(helper);
            }
            if (data.isRoot) {
              data.group.currentValue = data.groupValue;
              data.group.defaultValue = data.groupValue;
            } else if (data.groupValue) {
              data.group.value = data.groupValue;
            }
            data.group.add(mesh);
          })),
      loadFont("assets/sf_pro.typeface.json")
        .then(font => {
          textTextures.forEach(textureData => {
            const geometry = new TextGeometry(textureData.text, {
              font: font,
              size: 0.9,
              height: 0.1,
            });
            geometry.center();
            const mesh = new Mesh(geometry, textureData.material);
            mesh.arValue = textureData.value;
            mesh.position.set(0, 1.3, 0);
            mesh.visible = textureData.visible;
            mesh.name = "text";
            textureData.group.add(mesh);
          });
        }),
    ]).then(() => setLoading(false)).catch(e => console.log(e));
    
    const renderSceneObjects = () => {
      if (group5.visible && group5.helper) {
        group5.helper.update();
        group5.collisionBox.setFromObject(group5.helper);
      }
      
      if (group3.visible && group3.helper) {
        group3.helper.update();
        group3.collisionBox.setFromObject(group3.helper);
      }
      
      if (group2.visible && group2.helper) {
        group2.helper.update();
        group2.collisionBox.setFromObject(group2.helper);
      }
      
      if (group5.visible) {
        if (store.collisionObjectId) {
          const collisionTarget = scene.children.find(child => child.id === store.collisionObjectId);
          if (collisionTarget.visible) {
            const intersects = collisionTarget.collisionBox.intersectsBox(group5.collisionBox);
            if (intersects) {
              if (group5.currentValue !== group5.defaultValue + collisionTarget.value) {
                group5.currentValue = group5.defaultValue + collisionTarget.value;
              }
              group5.children.forEach(child => {
                child.visible = Boolean(child.arValue === group5.currentValue);
              });
              return;
            }
          }
        }
        store.collisionObjectId = null;
        if (group2.visible) {
          checkObjectsIntersection(group2, group5);
        }
        if (group3.visible) {
          checkObjectsIntersection(group3, group5);
        }
      } else {
        store.collisionObjectId = null;
        const group3Text = group3.getObjectByName("text");
        const group2Text = group2.getObjectByName("text");
        if (group3Text) {
          group3Text.visible = true;
        }
        if (group2Text) {
          group2Text.visible = true;
        }
      }
    };
    
    const animate = () => {
      requestId = requestAnimationFrame(animate);
      renderer.render(scene, camera);
      updateToolkitContent();
      renderSceneObjects();
    };
    
    requestId = requestAnimationFrame(animate);
    
    return () => {
      renderer.dispose();
      destroy();
      if (requestId) {
        cancelAnimationFrame(requestId);
      }
    }
  }, []);
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div
          style={{
            position: "fixed",
            top: 15,
            left: "50%",
            transform: "translateX(-50%)",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.75",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100000,
            fontSize: 25,
            padding: 10,
            borderRadius: 5,
          }}>
          5 + ? = 8
          <span>
            Choose correct number and connect it with the 5.
          </span>
        </div>
      )}
      <CloseButton onClick={onClose} />
      <canvas ref={canvasRef} />
    </div>
  
  );
};

MathScene.propTypes = propTypes;
MathScene.defaultProps = defaultProps;

export default MathScene;
